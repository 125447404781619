import { useQuery } from '@tanstack/react-query';
import classnames from 'classnames';
import { LoadingSpinner } from 'components/Common/LoadingSpinner';
import { useBodyLock } from 'hooks/useBodyLock';
import React from 'react';
import { fetchSearchResults, SearchResults } from './api';
import { useSearchContext } from './Search.Context';
import { PopularSearches } from './Partials/PopularSearches';
import { PopularAccessories } from './Partials/PopularAccessories';
import { PopularSmartphones } from './Partials/PopularSmartphones';
import { BestSellers } from './Partials/BestSellers';
import { urlToRelative } from 'components/Common/url-to-relative';

export function Search(): JSX.Element {
	const {
		state,
		handleChange,
		handleClose,
		handleKeyDown,
		handleOpen,
	} = useSearchContext();
	useBodyLock(state.focus);

	const { data: rawData, status, isFetching } = useQuery({
		queryKey: ['search', state.query],
		queryFn: ({ queryKey: [, query], signal }) =>
			fetchSearchResults(query, signal),
	});
	const data: SearchResults = rawData?.data ?? {
		handsets: [],
		accessories: [],
		eventPages: [],
	};
	// react-query v4 snafu, should be fixed in v5
	const isLoading = status === 'loading' && isFetching;

	return (
		<>
			<div
				className={classnames('SearchBox', {
					SearchBox__Focus: state.focus,
				})}
			>
				<button
					className="SearchBox--IcoBack"
					onClick={handleClose}
					aria-label="Close search"
				>
					<i className="ico ico-chevron-left"></i>
				</button>
				<div className={classnames('SearchBox--Container')}>
					<form
						action={urlToRelative(
							globalThis.Router('handset.search.results')
						)}
						method="get"
					>
						<input
							type="text"
							name="query"
							placeholder="Search for products or advice"
							ref={state.inputRef}
							onChange={handleChange}
							onClick={handleOpen}
							onKeyDown={handleKeyDown}
						/>
						<button
							className="SearchBox--IcoClose"
							onClick={handleClose}
							aria-label="Close search"
						>
							<i className="ico ico-close" />
						</button>
						<button
							className="SearchBox--IcoSearch"
							onClick={handleOpen}
							aria-label="Search"
							type="submit"
						>
							{isLoading ? (
								<LoadingSpinner />
							) : (
								<i className="ico ico-search" />
							)}
						</button>
					</form>
				</div>
			</div>

			<div className="SearchDrop">
				<div className="SearchDrop--Container">
					{state.focus && (
						<>
							<div className="SearchDrop--Sections">
								<BestSellers />
								<PopularSmartphones />
								{data.accessories.length === 0 ||
								state.query == '' ? (
									<PopularSearches />
								) : (
									<PopularAccessories />
								)}
							</div>
							<div className="SearchDrop--Proposition">
								<ul>
									<li>
										<i className="ico ico-cashback-gbp"></i>
										We&apos;re cheaper than the leading
										networks
									</li>
									<li>
										<i className="ico ico-cashback-gbp"></i>
										Great extras included with every phone
									</li>
									<li>
										<i className="ico ico-next-day-delivery"></i>
										Free next day delivery on every order
									</li>
								</ul>
							</div>
						</>
					)}
				</div>
				<div
					className={classnames('SearchDrop--Overlay', {
						'SearchDrop--Overlay__Focus': state.focus,
					})}
					onClick={handleClose}
					aria-hidden="true"
				>
					&nbsp;
				</div>
			</div>
		</>
	);
}
