import { useState, useEffect, useRef, useReducer } from 'react';

export enum HttpMethod {
	GET = 'GET',
	POST = 'POST',
	PUT = 'PUT',
	DELETE = 'DELETE',
}

interface State<T> {
	response?: T;
	error?: Error;
	isLoading: boolean;
}

interface HttpPayload {
	url: string;
	method: HttpMethod;
	body?: any;
	headers?: Record<string, unknown>;
}

interface FetchOptions {
	method?: HttpMethod;
	headers: any;
	body?: any;
	signal: any;
}

type Cache<T> = { [url: string]: T };

type Action<T> =
	| { type: 'loading' }
	| { type: 'fetched'; payload: T }
	| { type: 'error'; payload: Error };

export const useFetch = <T = unknown>({
	url,
	method,
	body,
	headers: additionalHeaders,
}: HttpPayload) => {
	const cache = useRef<Cache<T>>({});
	const [csrf, setCsrf] = useState<string | undefined>(undefined);

	const initialState: State<T> = {
		response: undefined,
		error: undefined,
		isLoading: true,
	};

	const reducer = (state: State<T>, action: Action<T>): State<T> => {
		switch (action.type) {
			case 'loading':
				return { ...initialState };
			case 'fetched':
				return {
					...initialState,
					isLoading: false,
					response: action.payload,
				};
			case 'error':
				return {
					...initialState,
					isLoading: false,
					error: action.payload,
				};
			default:
				return state;
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	useEffect(() => {
		// Abort to avoid memory leaks
		const abortControl = new AbortController();

		if (!state.isLoading) dispatch({ type: 'loading' });

		// return cached data from useRef.
		if (cache.current[url]) {
			dispatch({ type: 'fetched', payload: cache.current[url] });
			return;
		}

		// Check if we need a CSRF token if so generate it and set it.
		if (method && !csrf && method !== HttpMethod.GET) {
			fetch('/api/csrf', {
				signal: abortControl.signal,
			})
				.then(r => r.text())
				.then(setCsrf);
			return;
		}

		const headers = new Headers({
			'X-CSRF-TOKEN': csrf,
			...(additionalHeaders || {}),
		});

		const options: FetchOptions = {
			method,
			headers,
			signal: abortControl.signal,
		};

		if (body && method !== HttpMethod.GET) {
			options.body = body;
		}

		fetch(url, options)
			.then(res => {
				// Check if response is JSON if so return json else text.
				return res.headers
					.get('content-type')
					?.includes('application/json')
					? res.json()
					: res.text();
			})
			.then((data: T) => {
				cache.current[url] = data;
				dispatch({ type: 'fetched', payload: data });
			})
			.catch(err => {
				dispatch({ type: 'error', payload: err });
			});

		return () => {
			abortControl.abort();
		};
	}, [url, csrf]);

	return state;
};
