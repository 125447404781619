import React from 'react';
import classnames from 'classnames';

/**
 * Global loading spinner. Styling is in resources/sass/core/loading-spinner.scss
 */
function LoadingSpinner(props) {
  return (
    <div {...props} className={classnames('loading-spinner', props.className)}>
      <div className="bounce1"></div>
      <div className="bounce2"></div>
      <div className="bounce3"></div>
    </div>
  );
}
export { LoadingSpinner };
