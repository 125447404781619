import { KeyboardEvent, useEffect, useReducer, useRef } from 'react';
import {
	SearchAction,
	SearchReducer,
	SearchReducerProps,
} from '../Search.Reducer';
import { KeyCode } from 'enums/KeyCode.enum';

const initial: SearchReducerProps = {
	query: '',
	focus: false,
	handsets: [],
	accessories: [],
	eventPages: [],
	banners: [],
	inputRef: { current: null },
};

export function useSearch() {
	const inputRef = useRef<HTMLInputElement>(null);
	const [state, dispatch] = useReducer(SearchReducer, {
		...initial,
		inputRef,
	});
	const timeoutRef = useRef<ReturnType<typeof setTimeout>>(null);

	function handleChange(): void {
		if (timeoutRef.current) clearTimeout(timeoutRef.current);

		timeoutRef.current = setTimeout(() => {
			dispatch({
				type: SearchAction.QUERY,
				set: state.inputRef.current.value,
			});
		}, 250);
	}

	function handleOpen(): void {
		dispatch({ type: SearchAction.FOCUS, set: true });
		inputRef.current.focus();
	}

	function handleClose(): void {
		dispatch({ type: SearchAction.FOCUS, set: false });
	}

	function handleKeyDown(e: KeyboardEvent<HTMLInputElement>): void {
		if (KeyCode.Escape === e.key) {
			handleClose();
			state.inputRef.current.blur();
		}
	}

	useEffect(() => {
		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current);
			}
		};
	}, []);

	return {
		state,
		dispatch,
		handleChange,
		handleOpen,
		handleClose,
		handleKeyDown,
	};
}
