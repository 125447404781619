import React from 'react';
import { useSearchContext } from '../Search.Context';
import { useQuery } from '@tanstack/react-query';
import { fetchSearchResults, SearchResults } from '../api';
import { RetinaImage } from 'components/Common/Image';
import { boldify } from '../Helpers/boldify';
import { urlToRelative } from 'components/Common/url-to-relative';

export function PopularSmartphones() {
	const { state } = useSearchContext();

	const { data: rawData, isFetching, status } = useQuery({
		queryKey: ['search', state.query],
		queryFn: ({ queryKey: [, query], signal }) =>
			fetchSearchResults(query, signal),
	});

	const data: SearchResults = rawData?.data ?? {
		handsets: [],
		accessories: [],
		eventPages: [],
	};

	if (state.query == '') return <></>;

	const isLoading = status === 'loading' && isFetching;

	return (
		<div className="SearchDropSection SearchDropSection--PopularSmartphones">
			<div className="SearchDropSection--Title">Popular Smartphones</div>
			<ul className="SearchDropSection--Results">
				{data.handsets.map(handset => (
					<li
						className="SearchDropSection--Result"
						key={handset.title}
					>
						<a href={urlToRelative(handset.url)}>
							<div className="SearchDropSection--Result--Image">
								<RetinaImage
									displayHeight={30}
									path={
										handset.images.all[
											handset.images.featured
										]?.image
									}
									// We already show the name of the handset below, so intentionally leaving alt empty
									alt=""
								/>
							</div>
							<div className="SearchDropSection--Result--Title">
								<span
									dangerouslySetInnerHTML={{
										__html: boldify(
											handset.title,
											state.query
										),
									}}
								/>
							</div>
							{handset.price && (
								<div className="SearchDropSection--Result--Price">
									{handset.price}
								</div>
							)}
						</a>
					</li>
				))}
				{data.handsets.length === 0 && !isLoading ? (
					<p className="SearchDropSection--Result">
						No match please try again
					</p>
				) : null}
				{isLoading && (
					<p className="SearchDropSection--Result">Loading...</p>
				)}
			</ul>
		</div>
	);
}
