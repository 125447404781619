import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import ReactDOM from 'react-dom';
import { Search } from './Search';
import { SearchContextProvider } from './Search.Context';

const searchBar = document.querySelectorAll('.Header--Search');
if (searchBar) {
	const queryClient = new QueryClient();
	searchBar.forEach(el => {
		ReactDOM.render(
			<React.StrictMode>
				<QueryClientProvider client={queryClient}>
					<SearchContextProvider>
						<Search />
					</SearchContextProvider>
				</QueryClientProvider>
			</React.StrictMode>,
			el
		);
	});
}
