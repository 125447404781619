import { HandsetImages } from 'components/types';
import { MutableRefObject } from 'react';

interface BannerProps {
	image: string;
	url: string;
}

interface EventProps {
	title: string;
	url: string;
}

interface HandsetProps {
	images: HandsetImages;
	title: string;
	url?: string;
}
interface AccessoryProps {
	image: string;
	title: string;
	url?: string;
}

export interface SearchReducerProps {
	query: string;
	focus: boolean;
	handsets: HandsetProps[];
	accessories: AccessoryProps[];
	eventPages: EventProps[];
	banners: BannerProps[];
	inputRef: MutableRefObject<HTMLInputElement>;
}

export enum SearchAction {
	HANDSET,
	ACCESSORY,
	EVENT,
	BANNER,
	QUERY,
	FOCUS,
}

export type SearchActionProps =
	| { type: SearchAction.FOCUS; set: boolean }
	| { type: SearchAction.QUERY; set: string }
	| { type: SearchAction.HANDSET; set: HandsetProps[] }
	| { type: SearchAction.ACCESSORY; set: AccessoryProps[] }
	| { type: SearchAction.EVENT; set: EventProps[] }
	| { type: SearchAction.BANNER; set: BannerProps[] };

export function SearchReducer(
	state: SearchReducerProps,
	action: SearchActionProps
) {
	const newState = { ...state };
	switch (action.type) {
		case SearchAction.FOCUS:
			newState.focus = action.set;
			break;
		case SearchAction.QUERY:
			newState.query = action.set;
			break;
		case SearchAction.HANDSET:
			newState.handsets = action.set;
			break;
		case SearchAction.ACCESSORY:
			newState.accessories = action.set;
			break;
		case SearchAction.EVENT:
			newState.eventPages = action.set;
			break;
		case SearchAction.BANNER:
			newState.banners = action.set;
			break;
	}
	return newState;
}
