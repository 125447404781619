import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { MEDIA_BASE_URL } from 'config/media';

import { generateCdnImageUrl } from './generate-cdn-image-url';

import './RetinaImage.scss';

const RetinaImageProps = {
	/**
	 * @param path relative image path e.g. /handset/front.png
	 */
	path: PropTypes.string.isRequired,

	/**
	 * @param displayHeight the height at which to display the image
	 */
	displayHeight: PropTypes.number.isRequired,

	/**
	 * @param isYoutube for Youtube Thumbnails
	 */
	isYoutube: PropTypes.bool.isRequired,

	/**
	 * @param cdnOptions optional cdn settings to generate
	 * for s3 url e.g. { height: 100, fit: 'contain' }
	 * Note: if passed, these will be merged with retina settings!
	 */
	cdnOptions: PropTypes.any,

	/**
	 * @param alt alt text is required for accessibility
	 */
	alt: PropTypes.string.isRequired,
};

/**
 * An image that generates a URL for the image
 * at the height of displayHeight and uses
 * the normal URL of the image (without cdn-cgi)
 * for retina devices. Essentially, it assumes
 * that the original image is retina ready and uses
 * cdn-cgi to scale it down for default viewing
 *
 * Consider adding a "maxHeight" property to
 * limit the size of images
 *
 * Usage:
 *
 * <RetinaImage
    path="/images/promo/carousel/samsung-s10-lite-july-2020/product_lockup.png"
    displayHeight={248}
  />
 */
export function RetinaImage({
	path,
	alt,
	cdnOptions = { fit: 'contain' },
	displayHeight,
	isYoutube = false,
	...props
}: InferProps<
	typeof RetinaImageProps & React.ImgHTMLAttributes<HTMLImageElement>
>) {
	const defaultCdnOptions = {
		...cdnOptions,
		height: displayHeight,
		fit: 'contain',
	};

	const createYoutubeImageUrl = (url: string) => {
		const regex = /youtube\.com\/embed\/([a-zA-Z0-9_-]{11})/;
		const videoId = url.match(regex);
		return `https://img.youtube.com/vi/${videoId[1]}/0.jpg`;
	};

	// Generate a smaller version of the image for non retina
	const defaultImageUrl = isYoutube
		? createYoutubeImageUrl(path)
		: generateCdnImageUrl(MEDIA_BASE_URL, path, defaultCdnOptions);
	// Use the original image for retina
	// const retinaImageUrl = MEDIA_BASE_URL + path;
	const retinaImageUrl = isYoutube
		? createYoutubeImageUrl(path)
		: generateCdnImageUrl(MEDIA_BASE_URL, path, {
				...defaultCdnOptions,
				height: Number(displayHeight) * 2,
		  });

	if (isYoutube) {
		return (
			<div className="YoutubeThumbnailContainer">
				<img
					src={defaultImageUrl}
					srcSet={`${defaultImageUrl} 320w, ${retinaImageUrl} 640w`}
					height={displayHeight}
					alt={alt}
					{...props}
				/>
				<div className="YoutubeThumbnailContainer--PlayIcon" />
			</div>
		);
	}

	return (
		<img
			src={defaultImageUrl}
			srcSet={`${defaultImageUrl} 320w, ${retinaImageUrl} 640w`}
			height={displayHeight}
			alt={alt}
			{...props}
		/>
	);
}
RetinaImage.propTypes = RetinaImageProps;
