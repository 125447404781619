/**
 * Generates a url from cdn options
 *
 * @param base base URL
 * @param path relative path to image
 * @param options cdn options object
 */
export function generateCdnImageUrl(
  base: string,
  path: string,
  options: any
): string {
  let cdnCgiOptions = '';

  const optionList = [];

  for (const key in options) {
    const value = options[key];

    optionList.push(`${key}=${value}`);
  }
  optionList.push('format=auto');

  cdnCgiOptions = optionList.join(',');

  return `${base}/cdn-cgi/image/${cdnCgiOptions}${path}`;
}
