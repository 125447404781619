import React from 'react';
import { useSearchContext } from '../Search.Context';
import { useQuery } from '@tanstack/react-query';
import { fetchSearchResults, SearchResults } from '../api';
import { urlToRelative } from 'components/Common/url-to-relative';

export function PopularSearches() {
	const { state } = useSearchContext();

	const { data: rawData } = useQuery({
		queryKey: ['search', state.query],
		queryFn: ({ queryKey: [, query], signal }) =>
			fetchSearchResults(query, signal),
	});

	const data: SearchResults = rawData?.data ?? {
		handsets: [],
		accessories: [],
		eventPages: [],
	};

	return (
		<div className="SearchDropSection SearchDropSection--PopularSearches">
			<div className="SearchDropSection--Title">Popular Searches</div>
			<ul className="SearchDropSection--Result__Events">
				{data.eventPages.slice(0, 6).map(page => (
					<li
						className="SearchDropSection--Result__Event"
						key={page.url}
					>
						<a href={urlToRelative(page.url)}>{page.title}</a>
					</li>
				))}
			</ul>
		</div>
	);
}
