export enum KeyCode {
	Escape = 'Escape',
	Tab = 'Tab',
	Backspace = 'Backspace',
	Delete = 'Delete',
	ArrowLeft = 'ArrowLeft',
	ArrowRight = 'ArrowRight',
	ArrowUp = 'ArrowUp',
	ArrowDown = 'ArrowDown',
	End = 'End',
	Home = 'Home',
	Enter = 'Enter',
}
