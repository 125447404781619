import React from 'react';
import PropTypes, { InferProps } from 'prop-types';

import { MEDIA_BASE_URL } from 'config/media';

import { generateCdnImageUrl } from './generate-cdn-image-url';

export const ImageProps = {
  /**
   * @param path relative image path e.g. /handset/front.png
   */
  path: PropTypes.string,

  /**
   * @param cdnOptions optional cdn settings to generate
   * for s3 url e.g. { height: 100, fit: 'contain' }
   */
  cdnOptions: PropTypes.any,

  /**
   * @param alt alt text is required for accessibility
   */
  alt: PropTypes.string.isRequired,
};

/**
 * An image that optionally accepts options for,
 * and generates a cdn url string
 *
 * usage:
 * <Image path="/image/handset.png"/>
 *
 * <Image path="/image/handset.png" cdnOptions={{ height: 100, fit: "contain" }}/>
 */
function Image({
  path,
  cdnOptions = null,
  alt,
  ...props
}: InferProps<typeof ImageProps & React.ImgHTMLAttributes<HTMLImageElement>>) {
  const imageUrl =
    props.src || cdnOptions
      ? generateCdnImageUrl(MEDIA_BASE_URL, path, cdnOptions)
      : MEDIA_BASE_URL + path;

  return <img src={imageUrl} alt={alt} {...props} />;
}

Image.propTypes = ImageProps;

export { Image };
