import { useLayoutEffect, useRef } from 'react';

export function useBodyLock(lock = true) {
	const bodyRef = useRef<HTMLBodyElement>(document.querySelector('body'));

	useLayoutEffect(() => {
		if (lock) bodyRef.current.style.overflow = 'hidden';
		return () => {
			if (lock) bodyRef.current.style.overflow = '';
		};
	}, [lock]);
}
