import { urlToRelative } from 'components/Common/url-to-relative';
import { HandsetImages } from 'components/types';
import { Fetch, ResponseProps } from 'helpers/Fetch';
import { HttpMethod } from 'hooks/use-fetch';

interface HandsetResult {
	url: string;
	title: string;
	images: HandsetImages;
	price: string | null;
}

interface AccessoryResult {
	url: string;
	title: string;
	images: HandsetImages;
	price: string | null;
}
interface EventResult {
	url: string;
	title: string;
}

export interface SearchResults {
	handsets: HandsetResult[];
	accessories: AccessoryResult[];
	eventPages: EventResult[];
}

/**
 * @param query search query to send to laravel
 * @param signal `AbortController` signal used to stop in-flight requests if a new request is sent
 */
export async function fetchSearchResults(
	query: string,
	signal?: AbortSignal
): Promise<ResponseProps<SearchResults>> {
	const url = new URL(globalThis.Router('handset.search.v2').toString());
	url.searchParams.set('query', query);
	return Fetch<SearchResults>(urlToRelative(url.toString()), {
		method: HttpMethod.GET,
		signal,
	});
}
