import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useSearchContext } from '../Search.Context';
import { fetchSearchResults, SearchResults } from '../api';
import { RetinaImage } from 'components/Common/Image';
import { boldify } from '../Helpers/boldify';
import { urlToRelative } from 'components/Common/url-to-relative';

export function PopularAccessories() {
	const { state } = useSearchContext();

	const { data: rawData } = useQuery({
		queryKey: ['search', state.query],
		queryFn: ({ queryKey: [, query], signal }) =>
			fetchSearchResults(query, signal),
	});

	const data: SearchResults = rawData?.data ?? {
		handsets: [],
		accessories: [],
		eventPages: [],
	};

	return (
		<div className="SearchDropSection SearchDropSection--PopularAccessories">
			<div className="SearchDropSection--Title">Popular Accessories</div>
			<ul className="SearchDropSection--Results">
				{data.accessories.map(accessory => (
					<li
						className="SearchDropSection--Result"
						key={accessory.title}
					>
						<a href={urlToRelative(accessory.url)}>
							<div className="SearchDropSection--Result--Image">
								<RetinaImage
									displayHeight={30}
									path={
										accessory.images.all[
											accessory.images.featured
										]
									}
									alt=""
								/>
							</div>
							<div className="SearchDropSection--Result--Title">
								<span
									dangerouslySetInnerHTML={{
										__html: boldify(
											accessory.title,
											state.query
										),
									}}
								/>
							</div>
							{accessory.price && (
								<div className="SearchDropSection--Result--Price">
									{accessory.price}
								</div>
							)}
						</a>
					</li>
				))}
			</ul>
		</div>
	);
}
