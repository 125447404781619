import React, {
	createContext,
	Dispatch,
	KeyboardEvent,
	ReactNode,
	useContext,
} from 'react';
import { useSearch } from './Hooks/useSearch';
import { SearchActionProps, SearchReducerProps } from './Search.Reducer';

interface SearchContextProps {
	state: SearchReducerProps;
	dispatch: Dispatch<SearchActionProps>;
	handleChange: () => void;
	handleClose: () => void;
	handleOpen: () => void;
	handleKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const SearchContext = createContext<SearchContextProps>(null);

export function useSearchContext() {
	const ctx = useContext(SearchContext);

	if (!ctx) {
		throw new Error('useSearchContext must be used within a SearchContext');
	}

	return ctx;
}

export function SearchContextProvider({ children }: { children: ReactNode }) {
	const {
		state,
		dispatch,
		handleChange,
		handleClose,
		handleOpen,
		handleKeyDown,
	} = useSearch();

	return (
		<SearchContext.Provider
			value={{
				state,
				dispatch,
				handleChange,
				handleClose,
				handleOpen,
				handleKeyDown,
			}}
		>
			{children}
		</SearchContext.Provider>
	);
}
